import { useIds } from "hooks/ids";
import { read, write } from "lib/storage";
import { ConfigModel, CONFIG_STATE_KEY } from "models/config";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { fetchConfig } from "services/config";
import { sendCampaignEvent } from "services/event";

interface ConfigContextInterface {
  config: ConfigModel;
  updateConfig: (newConfig: ConfigModel) => void;
}

export const ConfigContext = createContext<ConfigContextInterface | undefined>(
  undefined
);

interface ConfigProviderProps {
  clean?: boolean;
  children: ReactNode;
}

const getCachedConfig = () => {
  return read<ConfigModel>(CONFIG_STATE_KEY);
};

const ConfigProvider: FunctionComponent<ConfigProviderProps> = ({
  children,
  clean = false,
}) => {
  // fetch the initial config from localStorage, unless we want a clean installation
  const [config, toggleConfig] = useState<ConfigModel | undefined>(undefined);
  const { campaignId, versionId } = useIds();

  const updateConfig = (newConfig?: ConfigModel) => {
    if (!newConfig) {
      return;
    }

    // update the local state
    toggleConfig(newConfig);
    console.info(`Updated Config Context`);

    // persist
    write(CONFIG_STATE_KEY, newConfig);

    // send event
    sendCampaignEvent({ uuid: newConfig.uuid, campaignId, versionId });
  };

  useEffect(() => {
    const init = async () => {
      console.info("ConfigProvider");
      console.info(` ==> Context is empty, trying to load...`);

      // fetch cached version
      const cachedConfig = getCachedConfig();
      if (!clean && cachedConfig) {
        updateConfig(cachedConfig);
        return;
      }

      try {
        // fetch config and store to session storage (plus local state)
        const newConfig = await fetchConfig(campaignId, versionId);
        console.info(` ==> Loaded from server. UUID = ${newConfig.uuid}`);

        // update to state
        updateConfig(newConfig);
        return;
      } catch (err) {
        console.error("Fetching config failed!");
      }
    };

    init();
  }, []);

  if (!config) {
    return null;
  }

  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
