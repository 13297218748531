import { ButtonBase } from "components/button.styles";
import { StyledH1 } from "components/title.styles";
import styled, { css } from "styled-components";
import devices from "theme/devices";

interface Props {
  $order: number;
}

export const StyledBlock = styled.div<Props>`
  position: initial;
  margin-bottom: 3rem;

  ${(props) => css`
    order: ${props.$order};
  `}

  /* button */
  ${ButtonBase} {
    margin-top: 2rem;
  }

  ${StyledH1} {
    padding-top: 0rem;
  }

  @media ${devices.tablet} {
    margin-bottom: 4rem;

    ${StyledH1} {
      padding-top: 0rem;
    }
  }

  @media ${devices.desktop} {
    flex: 1;

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    /* button */
    ${ButtonBase} {
      margin-top: 4rem;
    }
  }
`;
