import dayjs from "dayjs";
import { EntitlementModel } from "models/entitlement";

export const isEntitlementActive = (entitlement: EntitlementModel) => {
  const { status, end } = entitlement;

  // default entitlement triggers false
  if (end.includes("0001")) {
    return false;
  }

  // use status, if applicable
  if (status) {
    return status === "active" || status === "past_due";
  }

  // return true if the day is after
  return dayjs(end).isAfter(dayjs());
};

// check that is there is any past subsc
export const isEntitlementExpired = (entitlement: EntitlementModel) => {
  const { status, end } = entitlement;

  // default entitlement triggers false
  if (end.includes("0001")) {
    return false;
  }

  // use status, if applicable
  if (status) {
    return status === "deleted" || status === "paused";
  }

  // return true if the day is before
  return dayjs(end).isBefore(dayjs());
};
