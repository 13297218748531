import styled from "styled-components";
import devices from "theme/devices";

export const StyledLogos = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledLogo = styled.img`
  width: 8rem;
  align-self: center;

  @media ${devices.tablet} {
    width: 14rem;
  }

  @media ${devices.desktop} {
    width: auto;
  }
`;
