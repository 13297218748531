import NotFoundViewWrapper from "views/not-found/wrapper";
import QuestionnaireVersionView from "./view";
import { QuestionnaireVersion } from "models/questionnaire";
import { useParams } from "react-router-dom";
import { isSupported } from "lib/questionnaire";

const QuestionnaireVersionViewWrapper = () => {
  const { version } = useParams();
  const v = version as QuestionnaireVersion;

  if (Number.isNaN(v) || !isSupported(v)) {
    return <NotFoundViewWrapper />;
  }

  return <QuestionnaireVersionView />;
};

export default QuestionnaireVersionViewWrapper;
