import { FunctionComponent } from "react";
import { StyledDivider, StyledText } from "./divider.styles";
import { useTheme } from "hooks/theme";

export interface DividerProps {
  text?: string;
}

const Divider: FunctionComponent<DividerProps> = ({
  text = "Hello world!",
}) => {
  const theme = useTheme();
  return (
    <StyledDivider>
      <StyledText $theme={theme}>{text}</StyledText>
    </StyledDivider>
  );
};

export default Divider;
