import { StateContext } from "providers/state";
import { useContext } from "react";

export const useQuestionnaireState = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("No StateProvider found!");
  }
  return context.state;
};

export const useUpdateQuestionnaireState = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("No StateProvider found!");
  }
  return context.updateState;
};
