import { CountryType } from "./locale";
import { QuestionnaireVersion } from "./questionnaire";

export const CONFIG_STATE_KEY = "config";

export const DEVICE_ID_KEY = "deviceId";
export const CAMPAING_ID_KEY = "campaignId";
export const VERSION_ID_KEY = "versionId";

export interface ConfigModel {
  country: CountryType;
  uiLanguage: string;
  uuid: string;

  // feature flags
  questionnaireVersion?: QuestionnaireVersion;
  completed?: boolean;
  entitlementExists?: boolean;
}
