import styled from "styled-components";
import devices from "theme/devices";

export const StyledH1 = styled.h1`
  font-size: 1.75em;
  margin-bottom: 1.75rem;
  letter-spacing: -0.3px;
  line-height: 2.25rem;

  @media ${devices.tablet} {
    font-size: 2.5em;
    line-height: 1.3em;
    margin-top: 3rem;
    margin-bottom: 2.25rem;
    letter-spacing: -1px;
  }

  @media ${devices.desktop} {
    font-size: 3em;
    line-height: 1.3em;
    margin-top: 3rem;
    margin-bottom: 2.25rem;
  }
`;

export const StyledH2 = styled.h2``;

export const StyledH2WithH1 = styled.h2`
  font-size: 1.75em;
  margin-bottom: 1.75rem;
  letter-spacing: -0.3px;
  line-height: 2.25rem;

  @media ${devices.tablet} {
    font-size: 2.5em;
    line-height: 1.3em;
    margin-top: 3rem;
    margin-bottom: 2.25rem;
    letter-spacing: -1px;
  }

  @media ${devices.desktop} {
    font-size: 3em;
    line-height: 1.3em;
    margin-top: 3rem;
    margin-bottom: 2.25rem;
  }
`;
