import { QuestionnaireModel } from "models/questionnaire";
import { FollowUpModel } from "models/rules";
import { StateModel } from "models/state";
import { getLastAnsweredLevelQuestion } from "./questionnaire";
import { hasAnswer } from "./state";

export const parseFollowupRules = (
  questionnaire: QuestionnaireModel,
  followUps: FollowUpModel[],
  state: StateModel
) => {
  const matchingRule = followUps.find((followUp) => {
    return checkFollowup(questionnaire, state, followUp);
  });
  return matchingRule ? matchingRule.followUpQuestion : undefined;
};

export const checkFollowup = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  followUp: FollowUpModel
): boolean => {
  if (!followUp) {
    // ignore missing followUp
    return true;
  }

  const { type = "or", rules = [] } = followUp;

  if (rules.length === 0) {
    // ignore empty rules
    return true;
  }

  const passed = rules.filter((rule) => {
    const { lastLevelQuestion, questionId, optionId, schedule } = rule;

    // lastLevelQuestion
    if (lastLevelQuestion !== undefined) {
      const lastAnswered = getLastAnsweredLevelQuestion(questionnaire, state);
      if (
        !lastAnswered ||
        !(lastLevelQuestion || []).includes(lastAnswered.id)
      ) {
        return false;
      }
    }

    // question-option pair check
    if (
      questionId !== undefined &&
      optionId !== undefined &&
      !hasAnswer(state, questionId, optionId)
    ) {
      return false;
    }

    // question only check
    if (
      questionId !== undefined &&
      optionId === undefined &&
      !state.visitedQuestionIds.includes(questionId)
    ) {
      return false;
    }

    // study schedule check
    if (schedule !== undefined && state.schedule !== schedule) {
      return false;
    }

    // did not fail
    return true;
  });

  // if some of the requirements passed, show the component
  return type === "or" ? passed.length > 0 : passed.length === rules.length;
};
