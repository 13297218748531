import { ThemeType } from "models/theme";
import { css } from "styled-components";
import { ColorCode } from "./colors";
import { light } from "./fonts";

export const onetrust = (theme: ThemeType) => {
  return css`
    body #onetrust-consent-sdk {
      letter-spacing: inherit;

      #onetrust-policy-title {
        text-transform: uppercase;
        font-size: 1rem;
        color: ${ColorCode.BLACK};

        ${theme === "MasterEnglish" &&
        css`
          margin-bottom: 0.5rem;
        `};
      }

      #onetrust-banner-sdk #onetrust-policy-text {
        font-size: 0.875rem;
        color: ${ColorCode.GRAY800};
        line-height: 1.5;
        ${theme === "MasterEnglish" &&
        css`
          color: ${ColorCode.BLACK};
          line-height: 1.75;
          ${light()};
        `};
      }

      #onetrust-pc-sdk #ot-pc-title {
        color: ${ColorCode.BLACK};
      }

      #onetrust-pc-sdk #ot-pc-desc {
        line-height: 1.75;
        ${theme === "MasterEnglish" &&
        css`
          color: ${ColorCode.BLACK};
          line-height: 1.75;
          ${light()};
        `};
      }

      #onetrust-pc-sdk .ot-cat-grp {
        margin-bottom: 20px;
      }

      #onetrust-pc-sdk #ot-category-title {
        color: ${ColorCode.BLACK};
      }

      #onetrust-pc-sdk .ot-accordion-layout .ot-cat-header {
        color: ${ColorCode.BLACK};
      }

      #onetrust-pc-sdk .ot-acc-grpcntr.ot-acc-txt {
        background-color: ${ColorCode.WHITE};
      }

      #onetrust-banner-sdk.otCenterRounded {
        border-radius: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      @media only screen and (min-width: 426px) and (max-width: 896px) {
        #onetrust-banner-sdk.otCenterRounded {
          top: calc(15% - 42px);
          transform: translateY(0);
        }
      }

      #onetrust-banner-sdk .ot-close-icon {
        display: none;
      }

      #onetrust-banner-sdk #onetrust-button-group-parent {
        padding-top: 25px;
        padding-bottom: 25px;
      }

      #onetrust-banner-sdk #onetrust-button-group {
        display: inline-block;
        width: 100%;
      }

      #onetrust-banner-sdk .banner-actions-container {
        width: 100%;
      }

      #onetrust-accept-btn-handler,
      #onetrust-pc-sdk #accept-recommended-btn-handler,
      .save-preference-btn-handler {
        background-color: ${ColorCode.BLUE} !important;
        border-color: ${ColorCode.BLUE} !important;
        font-size: 0.875rem;
        letter-spacing: inherit;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 4px;
        width: 100%;
        max-width: none;

        ${theme === "MasterEnglish" &&
        css`
          background-color: ${ColorCode.CTA_RED} !important;
          border-color: ${ColorCode.CTA_RED} !important;
        `};
      }

      #ot-pc-content {
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 3px;
        margin-left: 0;
        margin-right: 0;
        width: auto;
        overflow: overlay;
      }

      .onetrust-pc-dark-filter {
        background: rgba(0, 0, 0, 0.25);
      }

      #onetrust-pc-sdk .ot-pc-footer {
        background-color: ${ColorCode.WHITE};
      }

      .ot-btn-container {
        padding-left: 30px;
        padding-right: 30px;
      }

      #onetrust-pc-sdk .ot-btn-container button {
        font-size: 0.875rem;
      }

      .save-preference-btn-handler {
        display: block;
        font-size: 1rem;
        width: 100%;
      }

      #onetrust-pc-btn-handler,
      #onetrust-pc-btn-handler.cookie-setting-link {
        color: ${ColorCode.BLACK};
        font-size: 0.875rem;
        letter-spacing: inherit;
        width: 100%;
        max-width: 100%;

        &:focus {
          outline: none;
          color: ${ColorCode.BLACK};
        }
      }

      @media only screen and (min-width: 426px) and (max-width: 896px) {
        #onetrust-banner-sdk .banner-actions-container {
          width: 50% !important;
        }

        #onetrust-accept-btn-handler {
          float: right;
          max-width: 45% !important;
          width: 45% !important;
        }

        #onetrust-pc-btn-handler {
          max-width: 45% !important;
          width: 45% !important;
        }
      }

      .ot-always-active {
        color: ${ColorCode.BLUE} !important;
      }
    }
  `;
};
