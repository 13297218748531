import View from "components/layout/view";
import { FunctionComponent, useEffect } from "react";
import Navbar from "components/navigation/navbar";
import ContentContainer from "components/layout/content";
import { TagMap, getUserTags } from "lib/tag";
import { useQuestionnaireState } from "hooks/state";
import { replaceTags } from "lib/string";
import Title from "components/title";
import List from "components/list";
import { StyledListHeading } from "components/list.styles";
import Description from "components/description";
import { write } from "lib/storage";
import { SKIP_TO_INSTALL_KEY } from "models/user";
import BulletList from "components/questionnaire/bullet-list";
import PaymentContent from "../question/content/base/types/payment";
import AppRatingContent from "../question/content/base/types/app-rating";
import { StyledBadge } from "./view.styles";
import { useDiscount } from "hooks/products";

const QuestionnaireReactivateView: FunctionComponent = () => {
  const state = useQuestionnaireState();
  const discount = useDiscount();

  useEffect(() => {
    // after payment, skip straight to install screen
    write<boolean>(SKIP_TO_INSTALL_KEY, true);
  }, []);

  // build a tagmap
  const tags: TagMap = {
    ...getUserTags(state),
  };

  const titleText = replaceTags("Reactiva tu programa de inglés", tags);

  // exceptionally parse months from the product
  const months = 6;

  const typeSubscription = `Tu suscripción se renovará mensualmente hasta que la canceles. Puedes cancelar tu suscripción y tu programa en cualquier momento.\n\nTe enviaremos un email 3 días antes de que se cumplan los ${months} meses que dura tu programa para que te acuerdes de cancelar tu suscripción antes de que se renueve el ${
    months + 1
  }º mes, si así lo deseas.`;

  return (
    <View title={titleText} description="" view="dark" cover>
      <Navbar transparent logoAsLink={false} />
      {discount && <StyledBadge filename="DiscountBadge" />}
      <ContentContainer>
        <Title>{titleText}</Title>
        <StyledListHeading>
          {"<span style='color: white; font-weight: 600;'>Objetivos:</span>"}
        </StyledListHeading>
        <List
          items={[
            {
              text: "Conseguir <span style='color: #007AFF;'>un nivel avanzado</span> de inglés",
            },
          ]}
        />
        <Description>
          Una vez que hayas reactivado tu programa, podrás ver en la aplicación
          móvil cuántas semanas de práctica te quedan para alcanzar un nivel
          avanzado.
        </Description>
        <BulletList
          paragraphs={[
            {
              id: 1,
              label: "Áreas de aprendizaje de inglés:",
              text: "Speaking, Listening, Reading, Writing y Grammar",
            },
            {
              id: 2,
              label: "Cómo funciona:",
              text: "Hemos guardado tu progreso anterior. Tu programa continuará desde donde lo dejaste. Después de que hayas reactivado tu programa, podrás iniciar sesión en la aplicación Master English con tu iPhone o teléfono Android. Nuestras lecciones personalizadas te permitirán ponerte al día rápidamente.",
            },
          ]}
          tags={tags}
        />
        <PaymentContent
          tags={tags}
          initial={false}
          onChange={() => {}}
          onSubmit={() => {}}
          handleScroll={() => {}}
          handleNavigation={async () => {}}
          content={{
            paymentOptions: {
              productSelector: "single-frame",
              noConfiguration: true,
              badge: discount ? "DiscountBadge2" : undefined,
            },
            paymentTexts: {
              description: "Sin compromiso. Cancela en cualquier momento.",
              paymentHeader:
                "Selecciona un método de pago para reactivar tu programa",
              title:
                "Inicia una suscripción mensual para reactivar tu programa",
              typeSubscription,
              paymentFooter:
                "Puedes cancelar tu suscripción y tu programa en cualquier momento. Para ello, envíanos un email a soporte@masterenglish.com.",
            },
            type: "payment",
          }}
        />
        <AppRatingContent
          tags={tags}
          initial={false}
          onChange={() => {}}
          onSubmit={() => {}}
          handleScroll={() => {}}
          handleNavigation={async () => {}}
          content={{
            appRatingStars: true,
            appRatingTexts: {
              def: "Nuestra valoración en la App Store es de 4.9/5.",
              mx: "Nuestra valoración en la App Store es de 4.9/5.",
            },
            type: "appRating",
          }}
        />
      </ContentContainer>
    </View>
  );
};

export default QuestionnaireReactivateView;
