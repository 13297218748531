import { Dayjs } from "dayjs";

export const DAY_MAP: string[] = [
  "lunes",
  "martes",
  "miércoles",
  "jueves",
  "viernes",
  "sábados",
  "domingos",
];

const MONTH_MAP: string[] = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

export const getDayName = (day: number) => {
  return DAY_MAP[day];
};

export const getMonthName = (month: number) => {
  return MONTH_MAP[month];
};

export const getMonthNameShort = (month: number) => {
  return getMonthName(month).slice(0, 3);
};
export const DATE_FORMAT = "D/M/YYYY";
export const DATE_FORMAT_RFC = "YYYY-MM-DD";

export const formatShort = (date: Dayjs) => {
  return date.format(DATE_FORMAT);
};

export const formatLong = (date: Dayjs) => {
  const day = date.format("D");
  const monthName = getMonthNameShort(date.month());
  const year = date.format("YYYY");
  return [day, monthName, year].join(" ");
};
