import { getPublicUrl } from "lib/env";
import { getMediaUrl } from "lib/media";
import { FunctionComponent } from "react";
import HelmetWrapper from "./helmet";

interface SharedHeadProps {
  title?: string;
  description?: string;
  image?: string;
  noindex?: boolean;
}

const DEFAULT = "Master English";

const SharedHead: FunctionComponent<SharedHeadProps> = ({
  title = DEFAULT,
  description = "",
  image,
  noindex = false,
}) => {
  const ogImgSrc = image || getMediaUrl("OG", { extension: "jpg" });
  const ogImgFullUrl = `${getPublicUrl()}${ogImgSrc}`.replace(
    "//images",
    "/images"
  );

  return (
    <HelmetWrapper>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImgFullUrl} />
      {noindex && <meta name="robots" content="noindex" />}
    </HelmetWrapper>
  );
};

export default SharedHead;
