import { FunctionComponent, useEffect, useState } from "react";
import { resetStorage, write } from "lib/storage";
import {
  ActiveQuestionnaireVersions,
  ControlQuestionnaireVersion,
} from "models/questionnaire";
import { useTranslations } from "hooks/translations";
import View from "components/layout/view";
import ContentContainer from "components/layout/content";
import ActionContainer, { ActionType } from "components/layout/actions";
import Title from "components/title";
import Description from "components/description";
import {
  getQuestionnaireUrl,
  getRaffleUrl,
  getStaticUrl,
} from "lib/questionnaire";
import { getAllApiUrls, getApiUrl, getEnv, setApiUrl } from "lib/env";
import List from "components/list";
import { getVersion } from "lib/version";
import { fetchConfig } from "services/config";
import { ConfigModel, CONFIG_STATE_KEY } from "models/config";
import Loader from "components/loader";
import { ListItemModel } from "models/content/list";
import { loadCleanQuestionnaire } from "services/questionnaire";
import { getPlatform, getPlatformVersion } from "lib/platform";
import { StaticViewPathEnum } from "models/question";
import dayjs from "dayjs";

const timestamp = dayjs(import.meta.env.VITE_BUILD_TIMESTAMP || dayjs());

interface VersionModel {
  text: string;
  url: string;
}

interface ResetVieProps {}

const QuestionnaireResetView: FunctionComponent<ResetVieProps> = ({}) => {
  const t = useTranslations();
  const raffleUrl = getRaffleUrl(t);
  const [versions, toggleVersions] = useState<VersionModel[]>([]);
  const [apiUrl, toggleApiUrl] = useState<string>(getApiUrl());
  const [config, toggleConfig] = useState<ConfigModel | undefined>(undefined);
  const loginUrl = getStaticUrl(
    t,
    ControlQuestionnaireVersion,
    StaticViewPathEnum.LOGIN_WITH_PASSWORD
  );

  const fetchNewConfig = async () => {
    // load a new config
    const newConfig = await fetchConfig(undefined, undefined);
    toggleConfig(newConfig);

    // persist config
    write(CONFIG_STATE_KEY, newConfig);
    return newConfig;
  };

  useEffect(() => {
    // clear storage, including config
    resetStorage();

    // preload questionnaires
    const init = async () => {
      // fetch new config
      const newConfig = await fetchNewConfig();

      // add versions
      const versions: VersionModel[] = [];
      for (let i = 0; i < ActiveQuestionnaireVersions.length; i += 1) {
        const version = ActiveQuestionnaireVersions[i];
        const questionnaire = await loadCleanQuestionnaire(
          newConfig.uuid,
          version,
          false
        );
        const url = getQuestionnaireUrl(
          t,
          version,
          questionnaire.initialQuestion
        );
        versions.push({ text: `Start questionnaire ${version}`, url });
      }
      toggleVersions(versions);
    };
    init();
  }, []);

  const navigate = (targeturl: string) => {
    // use native method to trigger full load
    window.location.assign(targeturl);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: "Home page",
        type: "default",
        onClick: () => {
          navigate("/");
        },
        href: "/",
      },
    },
    {
      type: "button",
      button: {
        text: "Raffle questionnaire",
        type: "primary",
        onClick: () => {
          navigate(raffleUrl);
        },
        href: raffleUrl,
      },
    },
    {
      type: "button",
      button: {
        text: "Login with password",
        type: "primary",
        onClick: () => {
          navigate(loginUrl);
        },
        href: loginUrl,
      },
    },
    {
      type: "button",
      button: {
        text: "Switch API url",
        type: "default",
        onClick: async () => {
          // pick the next api url
          const apiUrls = getAllApiUrls();
          const currentIndex = apiUrls.indexOf(apiUrl);
          const newApiUrl = apiUrls[currentIndex + 1] || apiUrls[0];
          toggleApiUrl(newApiUrl);

          // also, persist it
          setApiUrl(newApiUrl);

          // fetch new config right away, since the API changes
          await fetchNewConfig();
        },
        href: "/",
      },
    },
  ];

  versions.forEach((version) => {
    const { text, url } = version;
    actions.push({
      type: "button",
      button: {
        text,
        type: "default",
        onClick: async () => {
          navigate(url);
        },
        href: url,
      },
    });
  });

  if (!config) {
    return <Loader />;
  }

  const { uuid, country } = config;
  const listItems: ListItemModel[] = [
    {
      title: "Frontend version",
      text: `${getVersion()} [deployed ${timestamp.format(
        "DD/MM/YYYY HH:mm"
      )}]`,
    },
    { title: "Environment", text: getEnv() },
    { title: "Platform", text: `${getPlatform()} ${getPlatformVersion()}` },
    { title: "UserAgent", text: navigator.userAgent },
    { title: "API Url", text: apiUrl },
    { title: "User uuid", text: uuid },
    { title: "User country", text: country },
  ];

  return (
    <View title="State reset" description="">
      <ContentContainer>
        <Title>Reset done!</Title>
        <Description>Browser memory is now cleared!</Description>
        <List type="none" items={listItems} />
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireResetView;
