import { ThemeType } from "models/theme";
import { css } from "styled-components";
import { archivo, semibold } from "./fonts";
import { ColorCode } from "./colors";

export const CookieYes = (theme: ThemeType) => css`
  body {
    div.cky-overlay {
      background: rgba(0, 0, 0, 0.25);
      opacity: 1;
    }

    div.cky-consent-container {
      transform: translateX(-50%) translateY(-50%);
      top: 50%;

      div.cky-consent-bar {
        border-radius: 10px;
        margin: 0 auto;
        width: 95%;
        box-shadow: none;

        @media (max-width: 440px) {
          padding: 20px 0;
        }
      }
    }

    @media only screen and (min-width: 426px) and (max-width: 896px) {
      div.cky-consent-container {
        top: calc(15% - 42px);
        transform: translateX(-50%) translateY(0);
      }
    }

    .cky-notice .cky-title {
      ${semibold()};
      font-size: 1rem;
      text-transform: uppercase;
      color: rgb(5, 5, 5);
      line-height: 1.5rem;
      margin-top: 1rem;
      ${theme === "HablaIngles" &&
      css`
        ${archivo()};
      `}
      padding: 0 22px;
    }

    .cky-notice-des {
      font-size: 0.875rem;
      color: rgb(125, 129, 145) !important;
      line-height: 1.5;
      padding: 0 22px;
    }

    .cky-notice-btn-wrapper {
      padding: 0 22px;
      margin-top: 2rem;
    }

    @media only screen and (min-width: 426px) and (max-width: 896px) {
      .cky-notice-btn-wrapper {
        display: block;

        button:first-child {
          width: 45%;
          max-width: 45%;
          display: inline-block;
        }

        button:last-child {
          float: right;
          width: auto;
          max-width: 45%;
          padding: 12px 10px;
        }
      }
    }

    .cky-btn-accept {
      background-color: ${ColorCode.BLUE} !important;
      border-color: ${ColorCode.BLUE} !important;
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: inherit;
      padding-top: 16px;
      padding-bottom: 16px;
      border-radius: 4px;
      line-height: 1rem;

      ${theme === "MasterEnglish" &&
      css`
        background-color: ${ColorCode.CTA_RED} !important;
        border-color: ${ColorCode.CTA_RED} !important;
      `};
    }

    .cky-btn-customize {
      background-color: transparent !important;
      border-color: transparent !important;
      font-size: 0.875rem;
      font-weight: 600;
      color: ${ColorCode.BLACK} !important;
      text-decoration: underline;
      letter-spacing: inherit;
      padding-top: 16px;
      padding-bottom: 16px;
      border-radius: 4px;
      line-height: 1rem;

      ${theme === "MasterEnglish" && css``};
    }
  }
`;
