import { request } from "lib/request";
import { jwtDecode } from "jwt-decode";
import { QuestionnaireVersion } from "models/questionnaire";
import { UserModel } from "models/user";

export interface LoginResponseModel {
  features: {
    questionnaire: {
      completed: boolean;
      version: QuestionnaireVersion;
      entitlementExists: boolean;
    };
  };
  token: string;
  refreshToken: string;
  user: UserModel;
}

export const login = async (email: string, password: string) => {
  const response = await request<LoginResponseModel>({
    method: "POST",
    url: "/v1/login",
    data: { email, password },

    // not not auth this endpoint
    authenticated: false,
  });
  return response.data;
};

export const refreshTokens = async (refreshToken: string) => {
  const response = await request<LoginResponseModel>({
    method: "POST",
    url: "/v1/refresh",
    data: { refreshToken },

    // not not auth this endpoint
    authenticated: false,
  });
  return response.data;
};

export const checkEmail = async (email: string) => {
  const response = await request<any>({
    method: "POST",
    url: "/v1/check-email",
    data: { email },
  });
  return response.data;
};

export const forgotPassword = async (
  email: string,
  target?: QuestionnaireVersion,
) => {
  let url = "/v1/forgot-password";
  if (target) {
    url += `?target=${target}`;
  }

  const response = await request<any>({
    method: "POST",
    url,
    data: { email },

    // not not auth this endpoint
    authenticated: false,
  });
  return response.data;
};

interface TokenModel {
  exp: number;
}

export const getTokenExpire = (token: string) => {
  const decoded = jwtDecode<TokenModel>(token);
  const { exp } = decoded;

  // return true if expireDate is later than current
  const expireDate = exp * 1000;
  const currentDate = new Date().valueOf();
  console.info(`Token expires in ${(expireDate - currentDate) / 1000} seconds`);
  return expireDate - currentDate;
};

export const resetUser = async () => {
  await request({
    method: "POST",
    url: "/v1/reset-user",
    data: { resetAll: true },
  });
};
