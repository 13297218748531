import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { regular } from "theme/fonts";

interface Props {
  $desktop: boolean;
}

export const StyledLink = styled.a<Props>`
  color: ${ColorCode.WHITE};
  -webkit-tap-highlight-color: transparent;

  ${(props) =>
    !props.$desktop &&
    css`
      @media ${devices.desktop} {
        display: none !important;
      }
    `}

  &:first-of-type {
    margin-top: 3rem;
  }

  padding-right: 0 !important;
  padding-left: 0 !important;

  ${regular()};

  text-transform: uppercase;
  text-decoration: none;

  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.5;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  @media ${devices.tablet} {
    font-size: 1.25em;
    height: 8rem;

    &:first-of-type {
      margin-top: 6rem;
    }
  }

  @media ${devices.desktop} {
    display: inline-block;
    margin-right: 5rem;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;
