import { FunctionComponent, useEffect } from "react";

const isSandbox = import.meta.env.VITE_PUBLIC_PADDLE_SANDBOX === "true";
const initScriptCode = `
  ${isSandbox ? 'Paddle.Environment.set("sandbox");' : ""}
  Paddle.Setup({
    vendor: ${import.meta.env.VITE_PUBLIC_PADDLE_VENDOR_ID},
    eventCallback: function (data) {
      // Paddle react component will add delegatePaddleEvent method to handle events in a proper scope
      window.delegatePaddleEvent(data);
    },
  });
`;

const PaddleJS: FunctionComponent = () => {
  useEffect(() => {
    var loadScript = document.createElement("script");
    loadScript.async = true;
    loadScript.onload = function () {
      var initScript = document.createElement("script");
      initScript.type = "text/javascript";
      initScript.innerHTML = initScriptCode;
      document.head.appendChild(initScript);
    };
    loadScript.src = "https://cdn.paddle.com/paddle/paddle.js";
    document.head.appendChild(loadScript);
  }, []);

  return null;
};

export default PaddleJS;
