import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";

interface Props {
  $gray?: boolean;
}

export const StyledSectionWrapper = styled.div<Props>`
  @media ${devices.desktop} {
    ${(props) =>
      props.$gray &&
      css`
        background-color: ${ColorCode.HEX_f7f7f7};
      `}
  }
`;
