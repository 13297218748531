import { FunctionComponent } from "react";
import { StyledSection, StyledWrapper } from "./section.styles";
import { useTheme } from "hooks/theme";

interface SectionProps {
  children: any;
}

const Section: FunctionComponent<SectionProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <StyledSection $theme={theme}>
      <StyledWrapper>{children}</StyledWrapper>
    </StyledSection>
  );
};

export default Section;
