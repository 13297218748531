import { Language } from "./locale";

export interface UserModel {
  uuid?: string; // could be empty, for example when generating a new one
  email: string;
  password?: string;
  phone?: string;
  firstname: string;
  uiLanguage: Language;
}

// string
export const TOKEN = "token";

// string
export const REFRESH_TOKEN = "refreshToken";

// string
export const SIGNUP_EMAIL_KEY = "signupEmail";

// string
export const SIGNUP_NAME_KEY = "signupName";

// boolean, if true send /reset-user request after payment
export const RESET_AFTER_PURCHASE_KEY = "resetAfterPurchase";

// boolean, if true skip post-purchase views after payment
export const SKIP_TO_INSTALL_KEY = "skipToInstall";
