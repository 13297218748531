import styled, { css } from "styled-components";
import devices from "theme/devices";

import { ColorCode } from "theme/colors";
import Container from "./container";
import { ThemeProps } from "models/theme";

interface SectionProps extends ThemeProps {}

export const StyledSection = styled.div<SectionProps>`
  position: relative;
  display: block;
  background-color: ${ColorCode.WHITE};

  &:last-child {
    padding-bottom: 5rem;
  }

  @media ${devices.desktop} {
    min-height: 56rem;
  }

  @media (min-width: 2000px) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  ${(props) => css`
    ${props.$theme === "HablaIngles" &&
    css`
      background-color: ${ColorCode.HEX_F4F4F4};
    `}
  `}
`;

export const StyledWrapper = styled(Container)`
  display: block;
  padding-top: 0;
  padding-bottom: 0;

  @media ${devices.desktop} {
    display: flex;
    flex-direction: row;
    flex: 0;
    gap: 6rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    position: initial;
  }
`;
