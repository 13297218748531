import HelmetWrapper from "components/helmet";
import { useTheme } from "hooks/theme";
import { ColorCode } from "theme/colors";

const ThemeColor = () => {
  const theme = useTheme();
  let color: ColorCode | string = ColorCode.LAYOUT_DARK;
  if (theme === "HablaIngles") {
    color = "#030c41";
  }
  return (
    <HelmetWrapper>
      <meta name="theme-color" content={color} />
    </HelmetWrapper>
  );
};

export default ThemeColor;
