import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import { semibold } from "theme/fonts";
import { stretchToFullWidth } from "theme/mixins";

export const StyledDivider = styled.div`
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  border-top: 1px solid ${ColorCode.HEX_ced4da};
  ${stretchToFullWidth()};
  display: flex;
  justify-content: center;
`;

interface TextProps extends ThemeProps {}

export const StyledText = styled.div<TextProps>`
  display: inline-block;
  margin-top: -0.8rem;
  background: ${ColorCode.WHITE};
  color: ${ColorCode.BLACK};
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875em;
  ${semibold()};

  ${(props) => css`
    ${props.$theme === "HablaIngles" &&
    css`
      background-color: ${ColorCode.HEX_F4F4F4};
    `}
  `}
`;
