import { ProductModel } from "./product";

export type PaymentStatusType = "pending" | "confirmed" | "cancelled" | "n/a";

export type PaymentProvider = "paddle";

export const PAYMENT_STATUS_KEY = "paymentStatus";
export const PAYMENT_RETRY_URL_KEY = "paymentRetryUrl";

export interface PaymentStatus {
  status: PaymentStatusType;
  paymentProvider: PaymentProvider;
  product: ProductModel;
}
