import HelmetWrapper from "components/helmet";
import { getCookieYesId, isCookieYesEnabled } from "lib/cookie-yes";
import { gtmCookieYes } from "lib/gtm";
import { FunctionComponent, useEffect } from "react";

const CookieYes: FunctionComponent = () => {
  useEffect(() => {
    if (isCookieYesEnabled()) {
      gtmCookieYes();
    }
  }, []);

  if (isCookieYesEnabled() === false) {
    console.info("Cookie consent not available in this environment.");
    return null;
  }

  return (
    <HelmetWrapper>
      <script
        src={`https://cdn-cookieyes.com/client_data/${getCookieYesId()}/script.js`}
      ></script>
    </HelmetWrapper>
  );
};

export default CookieYes;
