import styled, { css } from "styled-components";
import devices from "theme/devices";

export const StyledLogo = styled.a`
  cursor: default;

  &[href] {
    cursor: pointer;
  }
`;

export const ImageCSS = css`
  width: 154px;
  height: 18px;
  margin-top: 3px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  object-fit: cover;
  display: block;

  @media ${devices.tablet} {
    width: 220px;
    height: 26px;
    margin-top: 5px;
  }

  @media ${devices.tablet} {
    width: 220px;
    height: 26px;
    margin-top: 5px;
  }
`;
