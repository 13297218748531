import Input from "components/form/input";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { FunctionComponent, useRef, useState } from "react";
import Description from "components/description";
import { write } from "lib/storage";
import { forgotPassword } from "services/user";
import { useTranslations } from "hooks/translations";
import {
  emailValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import { SIGNUP_EMAIL_KEY } from "models/user";
import { useNavigate } from "react-router-dom";

const RecoverPasswordView: FunctionComponent = () => {
  const t = useTranslations();
  const navigate = useNavigate();

  // check if we have email in the cache or as in query param
  const [email, setEmail] = useState("");
  const inputRef = useRef("");

  const handleEmailChange = (email: string) => {
    setEmail(email);
    write(SIGNUP_EMAIL_KEY, email);
  };

  const handleSubmit = async () => {
    // last minute check to avoid sending, if the email is wrong
    if (runValidations(email, validators) !== undefined && inputRef.current) {
      // get the input and force validation using focus+blur combo
      const input = inputRef.current as any;
      input.focus();
      input.blur();
      return;
    }

    try {
      await forgotPassword(email);
      navigate(t("/login/success"));
    } catch (err) {
      navigate(t("/login/failure"));
    }
  };

  const validators: ValidatorFn[] = [requiredValidator(t), emailValidator(t)];

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("login_button"),
        type: "primary",
        onClick: handleSubmit,
        href: t("/login/success"),
      },
    },
  ];

  return (
    <View
      title="¿Olvidaste tu contraseña?"
      description={t("login_body")}
      showLogoInFooter
      cover
    >
      <Navbar links />
      <ContentContainer>
        <Title>¿Olvidaste tu contraseña?</Title>
        <Description>{t("login_body")}</Description>
        <Input
          unique
          type="email"
          inputMode="email"
          autoFocus
          value={email}
          enterKeyHint="go"
          validators={validators}
          placeholder={t("Email")}
          onChange={handleEmailChange}
          onSubmit={handleSubmit}
          autoComplete="username"
          extRef={inputRef}
        />
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default RecoverPasswordView;
