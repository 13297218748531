import { RouteObject } from "react-router-dom";
import { lazy } from "react";

const LazyTermsOfUseViewWrapper = lazy(() => import("./terms-of-use/wrapper"));
const LazyPrivacyPolicyViewWrapper = lazy(
  () => import("./privacy-policy/wrapper")
);
const LazyLicensesViewWrapper = lazy(() => import("./licenses/wrapper"));

export const legalRoutes: RouteObject[] = [
  { path: "terminos-de-uso", element: <LazyTermsOfUseViewWrapper /> },
  { path: "es/terminos-de-uso", element: <LazyTermsOfUseViewWrapper /> },
  { path: "politica-de-privacidad", element: <LazyPrivacyPolicyViewWrapper /> },
  {
    path: "es/politica-de-privacidad",
    element: <LazyPrivacyPolicyViewWrapper />,
  },
  { path: "licencias", element: <LazyLicensesViewWrapper /> },
];
