import styled, { css } from "styled-components";
import devices from "theme/devices";

import Container from "./container";
import { ThemeProps } from "models/theme";

interface ActionsProps extends ThemeProps {
  $buttons: boolean;
}

export const StyledActions = styled(Container)<ActionsProps>`
  flex-shrink: 0;
  z-index: 60;
  padding-top: 1rem;
  padding-bottom: 3rem;

  ${(props) => css`
    ${props.$theme === "MasterEnglish" &&
    css`
      padding-bottom: 8rem;
    `}
    ${props.$theme === "HablaIngles" &&
    css`
      padding-top: 0.5rem;
    `}
  `}

  @media ${devices.desktop} {
    max-width: 720px;
    align-items: center;
  }
`;
