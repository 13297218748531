import { useTheme } from "hooks/theme";
import { FunctionComponent } from "react";
import { StyledContent, StyledRadio, StyledSelector } from "./radio.styles";
import Switch from "./switch";

interface RadioProps {
  children: any;
  active?: boolean;
  onSelect: () => void;
}
const Radio: FunctionComponent<RadioProps> = ({
  children,
  active = false,
  onSelect,
}) => {
  const theme = useTheme();
  return (
    <StyledRadio $active={active} $theme={theme} onClick={onSelect}>
      <StyledSelector>
        <Switch active={active} />
      </StyledSelector>
      <StyledContent $theme={theme}>{children}</StyledContent>
    </StyledRadio>
  );
};

export default Radio;
