import { StyledCol } from "components/layout/col.styles";
import Row from "components/layout/row";
import styled from "styled-components";
import devices from "theme/devices";

export const StyledRow = styled(Row)`
  flex-wrap: wrap;
  margin-top: 2rem;

  @media ${devices.desktop} {
    ${StyledCol} {
      width: 50%;
    }
  }
`;
