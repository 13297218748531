import rgba from "color-alpha";

export enum ColorCode {
  "CTA_RED" = "#f44646",
  "BITTERSWEET" = "#FF6565",
  "YELLOW" = "#ffba26",
  "SALMON" = "#fbafaf",
  "PINK" = "#ffe8e8",
  "RED" = "#fe5757",
  "LAYOUT_DARK" = "#070e21",
  "GREEN" = "#ccffd7",
  "GREEN_DARK" = "#0cb531",
  "GRAY800" = "#7d8191",
  "GRAY500" = "#aaaaaa",
  "BLACK" = "#050505",
  "WHITE" = "#ffffff",
  "WHITE_SMOKE" = "#f4f4f4",
  "MERCURY" = "#e7e7e7",
  "BLUE" = "#007aff",
  "LIGHT_BLUE" = "#3991ff",
  "LIGHT" = "#f3f3f3",
  "DARK" = "#1d2130",
  "HEX_REDDISH" = "#CA4040",
  "HEX_29324A" = "#29324a",
  "HEX_cdd1dd" = "#cdd1dd",
  "HEX_f7f7f7" = "#f7f7f7",
  "HEX_dbdbdb" = "#dbdbdb",
  "HEX_3342ff" = "#3342ff",
  "HEX_ced4da" = "#ced4da",
  "HEX_212529" = "#212529",
  "HEX_80bdff" = "#80bdff",
  "HEX_f3f2fa" = "#f3f2fa",
  "HEX_3a4769" = "#3a4769",
  "HEX_1B243C" = "#1B243C",
  "HEX_11192E" = "#11192E",
  "HEX_8C91A4" = "#8C91A4",
  "HEX_545869" = "#545869",
  "HEX_E24949" = "#E24949",
  "HEX_EA7676" = "#EA7676",
  "HEX_7F7F7F" = "#7F7F7F",
  "HEX_F4F4F4" = "#F4F4F4",
  "HEX_9C9C9C" = "#9C9C9C",
  "HEX_E4E4E4" = "#E4E4E4",
}

// converts any given color code to rgba versio
// alpha should be between [0, 1]
export const alpha = (colorCode: ColorCode, alpha: number) => {
  return rgba(colorCode, alpha);
};
