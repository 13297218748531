import { IdsContext } from "providers/ids";
import { useContext } from "react";

export const useIds = () => {
  const context = useContext(IdsContext);
  if (!context) {
    throw new Error("No IdsProvider found!");
  }
  return context;
};
