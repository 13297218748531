export const THEME_KEY = "theme";
export const DEFAULT_THEME: ThemeType = "Kosmo";

export type ThemeType =
  | "Kosmo" // Kosmo was old, original theme
  | "MasterEnglish" // Introduced with Visual Test
  | "HablaIngles"; // Introduced 2024 with Framer landing pages

// this can be used to attach "theme" prop to custom components
export interface ThemeProps {
  $theme?: ThemeType;
}
