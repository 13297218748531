import { ThemeContext } from "providers/theme";
import { useContext } from "react";

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("No ThemeProvider found!");
  }
  return context.theme;
};

export const useUpdateTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("No ThemeProvider found!");
  }
  return context.updateTheme;
};
