import { Fragment, FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ContentContainer from "components/layout/content";
import Tiles from "components/tiles";
import Navbar from "components/navigation/navbar";
import ActionContainer, { ActionType } from "components/layout/actions";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { StaticViewIdEnum, StaticViewPathEnum } from "models/question";
import { useConfig } from "hooks/config";
import { getStaticUrl, getVersion } from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router-dom";

const QuestionnaireStartingLevelTestView: FunctionComponent = () => {
  const t = useTranslations();
  const questionnaire = useQuestionnaire();
  const config = useConfig();
  const navigate = useNavigate();

  const href = getStaticUrl(
    t,
    getVersion(questionnaire),
    StaticViewPathEnum.START_LEVEL_TEST
  );

  const handleClick = () => {
    navigate(href);
  };

  useEffect(() => {
    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdEnum.STARTING_LEVEL_TEST,
    });
  }, []);

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("level_test_button"),
        type: "primary",
        onClick: handleClick,
        href,
      },
    },
  ];
  return (
    <Fragment>
      <View
        noindex
        view="dark"
        title={t("level_test_title")}
        description={t("level_test_text")}
        cover
      >
        <Tiles />
        <Navbar />
        <ContentContainer>
          <Title>{t("level_test_title")}</Title>
          <Description>{t("level_test_text")}</Description>
        </ContentContainer>
        <ActionContainer actions={actions} />
      </View>
    </Fragment>
  );
};

export default QuestionnaireStartingLevelTestView;
