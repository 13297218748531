import { ThemeProps } from "models/theme";
import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";

interface DescriptionProps extends ThemeProps {
  $view: ViewType;
}

export const StyledDescription = styled.div<DescriptionProps>`
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.HEX_8C91A4 : ColorCode.GRAY800};
  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      color: ${props.$view === "dark" ? ColorCode.HEX_8C91A4 : ColorCode.BLACK};
    `}
  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      color: ${props.$view === "dark"
        ? ColorCode.HEX_8C91A4
        : ColorCode.HEX_7F7F7F};
    `}

  margin-bottom: 1.5rem;
  max-width: 1000px;

  @media ${devices.tablet} {
    font-size: 1.25em;
    line-height: 1.5em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
    line-height: 1.5em;
  }
`;
