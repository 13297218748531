import View from "components/layout/view";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import Title from "components/title";
import { ConfigModel } from "models/config";
import {
  ControlQuestionnaireVersion,
  QuestionnaireVersion,
} from "models/questionnaire";
import { FunctionComponent, useState } from "react";
import {
  loadCleanQuestionnaire,
  saveQuestionnaireState,
} from "services/questionnaire";
import { SignupPayloadModel } from "services/signup";
import {
  emailValidator,
  passwordValidator,
  requiredValidator,
  runValidations,
} from "lib/validators";
import Input from "components/form/input";
import { useTranslations } from "hooks/translations";
import Dropdown, { DropDownItemModel } from "components/form/dropdown";
import { fetchConfig } from "services/config";
import { request } from "lib/request";
import { SignupModel } from "models/signup";
import { getVersion } from "lib/version";
import { buildState } from "lib/state";

const NAME = "Bob";

const QuestionnaireGenerateView: FunctionComponent = () => {
  const t = useTranslations();
  const [version, toggleVersion] = useState(
    String(ControlQuestionnaireVersion)
  );
  const [email, toggleEmail] = useState("");
  const [password, togglePassword] = useState("");
  const [state, toggleState] = useState(false);

  const onClick = async () => {
    // make signup
    try {
      const config = await fetchConfig(undefined, undefined);
      await makeSignup(
        config.uuid,
        version as QuestionnaireVersion,
        email,
        password
      );
      if (state) {
        await makeSaveQuestionnaire(
          config,
          version as QuestionnaireVersion,
          email
        );
      }
    } catch (err) {
      alert("Signup failed");
      throw err;
    }

    alert("Account created");
  };

  const emailValidations =
    runValidations(email, [emailValidator(t), requiredValidator(t)]) ===
    undefined;
  const passwordValidations =
    runValidations(password, [requiredValidator(t), passwordValidator(t)]) ===
    undefined;

  const action: ActionType = {
    type: "button",
    button: {
      text: "Go",
      type: "primary",
      href: "",
      onClick,
      disabled: !emailValidations || !passwordValidations,
    },
  };

  const title = `Generate account`;

  const items: DropDownItemModel[] = [
    { id: 0, text: "No state" },
    { id: 1, text: "Generate state" },
  ];

  return (
    <View title={title} description="">
      <ContentContainer>
        <Title>{title}</Title>
        <Input
          type="text"
          name="version"
          inputMode="text"
          autoFocus
          value={version}
          enterKeyHint="go"
          validators={[requiredValidator(t)]}
          label="Questionnaire version"
          placeholder="Enter questionnaire version..."
          onChange={(version) => toggleVersion(version)}
        />
        <Input
          type="email"
          name="email"
          inputMode="text"
          autoFocus
          value={email}
          enterKeyHint="go"
          validators={[requiredValidator(t), emailValidator(t)]}
          label="Email"
          placeholder="Enter email..."
          onChange={(email) => toggleEmail(email)}
        />
        <Input
          type="password"
          name="password"
          inputMode="text"
          autoFocus
          value={password}
          enterKeyHint="go"
          validators={[requiredValidator(t)]}
          label="Password"
          placeholder="Enter password..."
          onChange={(password) => togglePassword(password)}
        />
        <Dropdown
          name="target-question"
          label="Select days"
          items={items}
          onChoose={(value) => toggleState(Boolean(value))}
          selected={state ? 1 : 0}
        />
      </ContentContainer>
      <ActionContainer actions={[action]} />
    </View>
  );
};

export default QuestionnaireGenerateView;

const makeSignup = async (
  uuid: string,
  version: QuestionnaireVersion,
  email: string,
  password: string
) => {
  const signUpPayload: SignupPayloadModel = {
    uuid: uuid || "",
    firstname: NAME,
    email,
    password: password,
    uiLanguage: "es-419",
    questionnaire: version,
  };

  try {
    await request<SignupModel>({
      method: "POST",
      url: "/v1/signup",
      data: { ...signUpPayload, version: `v${getVersion()}` },

      // do not authenticate these requests
      authenticated: false,
    });
  } catch (_err) {
    // user already exists, logic differs later
    alert("User already exists");
  }
};

const makeSaveQuestionnaire = async (
  config: ConfigModel,
  version: QuestionnaireVersion,
  email: string
) => {
  const state = buildState(config);
  state.id = version;
  state.user.email = email;
  state.user.firstname = NAME;

  const questionnaire = await loadCleanQuestionnaire(
    config.uuid,
    version,
    false
  );

  // save to server
  await saveQuestionnaireState(questionnaire, state, true);
};
