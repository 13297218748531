import styled, { css } from "styled-components";
import devices from "theme/devices";

import { ColorCode } from "theme/colors";

export const StyledToggle = styled.div`
  padding: 0;
  position: relative;
  width: 22px;
  height: 22px;
  border: 0;
  user-select: none;

  @media ${devices.desktop} {
    display: none;
  }

  :focus {
    outline: none;
  }
`;

export const StyledButton = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  width: 22px;
  height: 22px;

  cursor: pointer;
  z-index: 1;
`;

interface Props {
  $collapsed: boolean;
}

export const StyledSpan = styled.span<Props>`
  transform: rotate(-45deg);

  &,
  &::before,
  &::after {
    display: block;
    position: absolute;

    width: 22px;
    height: 2px;

    background-color: ${ColorCode.WHITE};
    transition-duration: 0.25s;
  }

  &::before {
    content: "";
    top: 0;
    transform: rotate(0);
  }

  &::after {
    content: "";
    top: 0;
    transform: rotate(90deg);
  }

  ${(props) =>
    props.$collapsed &&
    css`
      transform: rotate(0);
      &::before {
        content: "";
        top: -8px;
      }
      &::after {
        content: "";
        top: 8px;
        transform: rotate(0deg);
      }
    `}
`;
