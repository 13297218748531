export const breakpoints = {
  mobile: 376,
  tablet: 768,
  desktop: 992,
};

export default {
  mobile: `(min-width: ${breakpoints.mobile}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`,
};

export const range = {
  mobile: `(max-width: ${breakpoints.tablet - 1}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px) and (max-width: ${
    breakpoints.desktop - 1
  }px)`,
  desktop: `(mix-width: ${breakpoints.desktop}px)`,
};
