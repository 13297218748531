import { ConfigModel } from "models/config";
import { QuestionnaireModel } from "models/questionnaire";
import { RequirementModel } from "models/requirement";
import { StateModel } from "models/state";
import { getPlatform } from "./platform";
import { getLastAnsweredLevelQuestion } from "./questionnaire";
import { hasAnswer } from "./state";

interface RequirementCheckProps {
  questionnaire: QuestionnaireModel;
  state?: StateModel;
  requirements?: RequirementModel;
  config?: ConfigModel;
}
// checks if the content etc matches requirements, returns true if any of the requirements is ok
export const checkRequirements = (props: RequirementCheckProps) => {
  const { state, config, requirements, questionnaire } = props;

  // if we don't have either config or state, do not check any further (build time case)
  if (!state || !config) {
    return true;
  }

  // ignore missing requirements
  if (!requirements) {
    return true;
  }

  const { type = "or", rules = [] } = requirements;
  if (rules.length === 0) {
    // ignore empty rules
    return true;
  }

  const passed = rules.filter((requirement) => {
    const {
      questionId,
      optionId,
      days,
      schedule,
      countryIs,
      countryIsNot,
      lastLevelQuestion,
      platform,
    } = requirement;

    // question-option pair check
    if (
      questionId !== undefined &&
      optionId !== undefined &&
      state &&
      !hasAnswer(state, questionId, optionId)
    ) {
      return false;
    }

    // days check
    if (days !== undefined) {
      if (days === "on" && state && !state.days) {
        return false;
      } else if (days === "off" && state && state.days) {
        return false;
      }
    }

    // question only check
    if (
      questionId !== undefined &&
      optionId === undefined &&
      state &&
      !state.visitedQuestionIds.includes(questionId)
    ) {
      return false;
    }

    // study schedule check
    if (schedule !== undefined && state && state.schedule !== schedule) {
      return false;
    }

    // country check
    if (countryIs !== undefined && config && config.country !== countryIs) {
      return false;
    } else if (
      countryIsNot !== undefined &&
      config &&
      config.country === countryIsNot
    ) {
      return false;
    }

    // last level question check
    if (lastLevelQuestion !== undefined) {
      const answered = getLastAnsweredLevelQuestion(questionnaire, state);
      if (answered && lastLevelQuestion.includes(answered.id) === false) {
        return false;
      }
    }

    // platform check
    if (platform !== undefined) {
      if (platform !== getPlatform()) {
        return false;
      }
    }

    // did not fail any of the checkes
    return true;
  });

  // if some of the requirements passed, show the component
  return type === "or" ? passed.length > 0 : passed.length === rules.length;
};
