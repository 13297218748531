import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { alpha, ColorCode } from "theme/colors";
import devices from "theme/devices";
import { archivo, semibold } from "theme/fonts";

interface Props extends ThemeProps {
  $loadingStatus: boolean;
  $disabled: boolean;
}

export const ButtonBase = styled.a<Props>`
  width: 100%;
  margin-bottom: 6px;
  outline: none;
  overflow: hidden;
  padding: 1rem;
  ${semibold()};
  -webkit-tap-highlight-color: transparent;

  border: 1px solid transparent;
  border-radius: 4px;

  display: block;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 0.875em;
  cursor: pointer;
  line-height: 1.25rem;
  user-select: none;
  position: relative;
  box-shadow: none;

  ${(props) => css`
    ${props.$loadingStatus &&
    css`
      // always hide text if loading
      color: transparent !important;
    `}
  `}

  &:focus,
  &:active {
    outline: none;
  }

  @media ${devices.tablet} {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1em;
    max-width: 375px;
    line-height: 1.5rem;
  }

  @media ${devices.desktop} {
    margin-bottom: 0.375rem;
    line-height: 1.5rem;
  }

  ${(props) => css`
    ${props.$theme === "MasterEnglish" &&
    css`
      padding: 1.15rem;
    `}

    ${props.$theme === "HablaIngles" &&
    css`
      border-radius: 16px;
      margin-bottom: 0.5rem;
      padding: 1rem;
      ${archivo()};
      font-weight: 500;
    `}
  `};
`;

export const StyledDefaultButton = styled(ButtonBase)<Props>`
  box-shadow: 0px 3px 6px ${alpha(ColorCode.BLACK, 0.161)};
  background-color: ${ColorCode.WHITE};
  color: ${ColorCode.BLACK};
  border: 1px solid ${alpha(ColorCode.BLACK, 0.03)};

  &:hover {
    color: ${ColorCode.BLACK};
  }

  &:active,
  &.down {
    background-color: ${ColorCode.LIGHT};
    color: ${ColorCode.GRAY500};
    box-shadow: none;
  }

  ${(props) => css`
    ${props.$disabled &&
    css`
      cursor: default;
      box-shadow: none; // force, applies also to default ones which to have box-shadow
      background-color: ${ColorCode.LIGHT};
      color: ${ColorCode.GRAY500};

      &:hover {
        color: ${ColorCode.GRAY500};
      }
    `}

    ${props.$theme === "MasterEnglish" && css``}

    ${props.$theme === "HablaIngles" &&
    css`
      box-shadow: none;

      ${props.$disabled &&
      css`
        background-color: ${ColorCode.MERCURY};
        border-radius: 16px;
      `}
    `}
  `};
`;

export const StyledPrimaryButton = styled(ButtonBase)<Props>`
  background-color: ${ColorCode.BLUE};
  color: ${ColorCode.WHITE};

  &:hover {
    color: ${ColorCode.WHITE};
  }

  &:active,
  &.down {
    background-color: ${ColorCode.LIGHT_BLUE};
    color: ${ColorCode.WHITE};
  }

  ${(props) => css`
    ${props.$disabled &&
    css`
      cursor: default;
      box-shadow: none; // force, applies also to default ones which to have box-shadow
      background-color: ${ColorCode.LIGHT};
      color: ${ColorCode.GRAY500};

      &:hover {
        color: ${ColorCode.GRAY500};
      }
    `}

    ${props.$theme === "MasterEnglish" &&
    css`
      background-color: ${ColorCode.CTA_RED};
      color: ${ColorCode.WHITE};

      &:hover {
        color: ${ColorCode.WHITE};
      }

      &:active,
      &.down {
        background-color: ${ColorCode.BITTERSWEET};
        color: ${ColorCode.WHITE};
      }
    `}

    ${props.$theme === "HablaIngles" &&
    css`
      background-color: ${ColorCode.HEX_E24949};
      border-radius: 50px;

      &:hover {
        background-color: ${ColorCode.HEX_EA7676};
      }

      &:active,
      &.down {
        background-color: ${ColorCode.HEX_EA7676};
      }

      ${props.$disabled &&
      css`
        background-color: ${ColorCode.MERCURY};
        color: ${ColorCode.HEX_9C9C9C};

        &:hover {
          background-color: ${ColorCode.MERCURY};
          color: ${ColorCode.HEX_9C9C9C};
        }
      `}
    `}
  `};
`;

export const StyledSecondaryButton = styled(ButtonBase)<Props>`
  background-color: ${ColorCode.HEX_29324A};
  color: ${ColorCode.WHITE};

  &:hover {
    color: ${ColorCode.WHITE};
  }

  &:active,
  &.down {
    background-color: ${ColorCode.HEX_3a4769};
    color: ${ColorCode.WHITE};
  }

  ${(props) => css`
    ${props.$disabled &&
    css`
      cursor: default;

      &:hover {
      }
    `}

    ${props.$theme === "MasterEnglish" && css``}

    ${props.$theme === "HablaIngles" &&
    css`
      border-radius: 50px;

      &:hover {
      }

      &:active,
      &.down {
      }
    `}
  `};
`;

export const StyledLinkButton = styled(ButtonBase)<Props>`
  background-color: transparent;
  text-decoration: underline;
  color: ${ColorCode.BLACK};

  &:hover {
    color: ${ColorCode.BLACK};
  }

  &:active,
  &.down {
    background-color: transparent;
  }

  ${(props) => css`
    ${props.$disabled &&
    css`
      cursor: default;

      &:hover {
      }
    `}

    ${props.$theme === "MasterEnglish" && css``}

    ${props.$theme === "HablaIngles" &&
    css`
      &:hover {
      }

      &:active,
      &.down {
      }
    `}
  `};
`;

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  pointer-events: none;
  user-select: none;

  & .path {
    stroke: ${ColorCode.HEX_f3f2fa};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
