import { read, write } from "lib/storage";
import {
  CAMPAING_ID_KEY,
  CONFIG_STATE_KEY,
  ConfigModel,
  DEVICE_ID_KEY,
  VERSION_ID_KEY,
} from "models/config";
import { createContext, FunctionComponent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { sendCampaignEvent } from "services/event";
import { v4 } from "uuid";

interface IdsContextInterface {
  deviceUuid: string;
  campaignId?: string;
  versionId?: string;
}

export const IdsContext = createContext<IdsContextInterface | undefined>(
  undefined
);

interface IdsProviderProps {
  children: any;
}

const IdsProvider: FunctionComponent<IdsProviderProps> = ({ children }) => {
  const [p] = useSearchParams();

  // DEVNOTE: the localStorage value is written immediately (and not in useEffect) before we need the value right away
  const generateDeviceId = () => {
    const uuid = read<string>(DEVICE_ID_KEY) || v4();
    write(DEVICE_ID_KEY, uuid);
    return uuid;
  };

  const generateCampaignId = () => {
    const id = p.get("cid") || read(CAMPAING_ID_KEY) || undefined;
    write(CAMPAING_ID_KEY, id);
    return id;
  };

  const generateVersionId = () => {
    const id = p.get("vid") || read(VERSION_ID_KEY) || undefined;
    write(VERSION_ID_KEY, id);
    return id;
  };

  // from localStorage, or generate a new one
  const [deviceUuid] = useState<string>(generateDeviceId());

  // const from url, or use one from localStorage
  const [campaignId] = useState<string | undefined>(generateCampaignId());
  const [versionId] = useState<string | undefined>(generateVersionId());

  useEffect(() => {
    // send event
    const uuid = read<ConfigModel>(CONFIG_STATE_KEY)?.uuid || ""; // default to empty string, if no uuid is found
    sendCampaignEvent({ uuid, campaignId, versionId });
  }, []);

  console.info(
    `deviceUuid = ${deviceUuid}, campaignId = ${campaignId}, versionId = ${versionId}`
  );

  return (
    <IdsContext.Provider value={{ deviceUuid, campaignId, versionId }}>
      {children}
    </IdsContext.Provider>
  );
};

export default IdsProvider;
