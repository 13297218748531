import { ViewContext } from "providers/view";
import { useContext } from "react";

export const useView = () => {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error("No ViewProvider found!");
  }
  return context.type;
};
