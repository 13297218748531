import QuestionnaireProvider from "providers/questionnaire";
import { useParams } from "react-router-dom";
import { QuestionnaireVersion } from "models/questionnaire";
import StateProvider from "providers/state";
import ConfigProvider from "providers/config";
import { StaticViewIdEnum } from "models/question";
import QuestionnaireNewConfirmView from "./view";

const QuestionnaireNewConfirmViewWrapper = () => {
  const { version: v } = useParams();
  const version = v as QuestionnaireVersion;

  return (
    <ConfigProvider>
      <StateProvider>
        <QuestionnaireProvider
          version={version}
          questionId={StaticViewIdEnum.NEW_CONFIRM}
        >
          <QuestionnaireNewConfirmView />
        </QuestionnaireProvider>
      </StateProvider>
    </ConfigProvider>
  );
};

export default QuestionnaireNewConfirmViewWrapper;
