import { FunctionComponent } from "react";
import Section from "components/layout/section";
import Title from "components/title";
import Description from "components/description";
import Background from "components/layout/background";
import Block from "components/layout/block";
import List, { ListItemProps } from "components/list";
import { useTranslations } from "hooks/translations";

interface ConfidentMaleProps {}

const ConfidentMale: FunctionComponent<ConfidentMaleProps> = () => {
  const t = useTranslations();

  const items: ListItemProps[] = [
    {
      title: t("section_3_description_bullet_1_bold"),
      text: t("section_3_description_bullet_1"),
    },
    {
      title: t("section_3_description_bullet_2_bold"),
      text: t("section_3_description_bullet_2_method"),
    },
    {
      title: t("section_3_description_bullet_3_bold"),
      text: t("section_3_description_bullet_3"),
    },
  ];

  return (
    <Section>
      <Block order={1}>
        <Title mode="h2-with-h1-styles">{t("section_3_title")}</Title>
        <Description>{t("section_3_description_p1_embedded")}</Description>
        <Description>{t("section_3_description_p2_embedded")}</Description>
        <List items={items} />
        <Description>{t("section_3_description_p3_embedded")}</Description>
        <Description>{t("section_3_description_p4_embedded")}</Description>
      </Block>
      <Block order={2}>
        <Background
          align="right"
          mobileImageUrl={"home/RedLadyMobileHomeBg@2x"}
          tabletImageUrl={"home/RedLadyTabletBg"}
          desktopImageUrl={"home/RedLadyDesktopHomeBg"}
          priority="low"
        ></Background>
      </Block>
    </Section>
  );
};

export default ConfidentMale;
