import { getInitialQuestion } from "lib/questionnaire";
import { ControlQuestionnaireVersion } from "models/questionnaire";
import ConfigProvider from "providers/config";
import QuestionnaireProvider from "providers/questionnaire";
import StateProvider from "providers/state";
import { useEffect, useState } from "react";
import { loadCleanQuestionnaire } from "services/questionnaire";
import { v4 } from "uuid";
import RecoverPasswordView from "./view";

const RecoverPasswordViewWrapper = () => {
  const version = ControlQuestionnaireVersion;
  const [questionId, setQuestionId] = useState<number | undefined>(undefined);

  useEffect(() => {
    const fn = async () => {
      // use bundled json
      const questionnaire = await loadCleanQuestionnaire(v4(), version, false);
      const questionId = getInitialQuestion(questionnaire).id;
      setQuestionId(questionId);
    };
    fn();
  }, []);

  if (!questionId) {
    return null;
  }

  return (
    <ConfigProvider>
      <StateProvider>
        <QuestionnaireProvider version={version} questionId={questionId}>
          <RecoverPasswordView />
        </QuestionnaireProvider>
      </StateProvider>
    </ConfigProvider>
  );
};

export default RecoverPasswordViewWrapper;
