import { FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import { read } from "lib/storage";
import Tiles from "components/tiles";
import { SIGNUP_EMAIL_KEY } from "models/user";
import { breakAll, highlightEmail } from "lib/string";
import Navbar from "components/navigation/navbar";
import { StaticViewIdEnum, StaticViewPathEnum } from "models/question";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { useConfig } from "hooks/config";
import { getStaticUrl, getVersion } from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router-dom";

const QuestionnaireVerifyEmailView: FunctionComponent = () => {
  const t = useTranslations();
  const questionnaire = useQuestionnaire();
  const config = useConfig();
  const version = getVersion(questionnaire);
  const navigate = useNavigate();

  // hrefs
  const hrefForCongratulations = getStaticUrl(
    t,
    version,
    StaticViewPathEnum.CONGRATULATIONS
  );
  const hrefForUpdateEmail = getStaticUrl(
    t,
    version,
    StaticViewPathEnum.UPDATE_EMAIL
  );

  useEffect(() => {
    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdEnum.VERIFY_EMAIL,
    });
  }, []);

  // get the email from storage
  const view = "dark";
  const email = read<string>(SIGNUP_EMAIL_KEY);
  const emailDescription = highlightEmail(view, breakAll(email || ""));

  const handleAccessProgram = () => {
    navigate(hrefForCongratulations);
  };

  const handleUpdateEmail = () => {
    navigate(hrefForUpdateEmail);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("check_email_button1"),
        type: "primary",
        onClick: handleAccessProgram,
        href: hrefForCongratulations,
      },
    },
    {
      type: "button",
      button: {
        text: t("check_email_button2"),
        type: "secondary",
        onClick: handleUpdateEmail,
        href: hrefForUpdateEmail,
      },
    },
  ];

  return (
    <View
      noindex
      view={view}
      description={t("check_email_p1")}
      title={t("check_email_title")}
      cover
    >
      <Tiles />
      <Navbar />
      <ContentContainer>
        <Title>{t("check_email_title")}</Title>
        <Description>{t("check_email_p1")}</Description>
        <Description>{emailDescription}</Description>
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireVerifyEmailView;
