import { FunctionComponent } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import routes from "./core";
import IdsProvider from "providers/ids";
import ErrorBoundary from "components/error-boundary";
import ThemeProvider from "providers/theme";

// create a router and wrap some contexts/utilities
const router = createBrowserRouter([
  {
    path: "/",
    children: routes,
    element: (
      <ThemeProvider>
        <ErrorBoundary>
          <IdsProvider>
            <Outlet />
          </IdsProvider>
        </ErrorBoundary>
      </ThemeProvider>
    ),
  },
]);

// the actual component
const Router: FunctionComponent = () => <RouterProvider router={router} />;

export default Router;
