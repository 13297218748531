import styled, { css } from "styled-components";
import devices from "theme/devices";

import { ColorCode } from "theme/colors";
import { ThemeProps } from "models/theme";

interface Props extends ThemeProps {
  $collapsed: boolean;
}

export const StyledCollapse = styled.div<Props>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  z-index: 110;
  position: fixed;
  overflow: hidden;
  top: 58px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${ColorCode.LAYOUT_DARK};
  transition-duration: 0.25s;
  transition-property: bottom;

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      background-color: #030c41;
    `}

  @media ${devices.tablet} {
    top: 88px;
  }

  @media ${devices.desktop} {
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  ${(props) =>
    props.$collapsed &&
    css`
      bottom: calc(100% - 58px);

      @media ${devices.tablet} {
        bottom: calc(100% - 88px);
      }

      @media ${devices.desktop} {
        bottom: auto;
      }
    `}
`;
