import { request } from "lib/request";
import { ProgramPlan } from "models/plan";

export interface FeatureResponse {
  flag: string;
  value: string;
}

export interface FeatureParams<T> {
  trafficId: string;
  attributes: T;
}

// generic feature request service
async function featureRequest<T>(feature: string, data: FeatureParams<T>) {
  const response = await request<FeatureResponse>({
    method: "POST",
    url: `/v1/feature/${feature}`,
    data,
  });

  // convert value
  const { value } = response.data;
  return value;
}

export interface ProductFeatureParams {
  questionnaire: string; // in 'v23' format
  plan: ProgramPlan;
  minutesPerWeek?: string;
  reactivate: boolean; // defaults to false
}

export const fetchProductIds = async (
  trafficId: string,
  attributes: ProductFeatureParams
) => {
  const data: FeatureParams<ProductFeatureParams> = {
    trafficId,
    attributes,
  };

  // convert value
  const value = await featureRequest("course-fee", data);
  const productIds = value.split("_");
  return productIds;
};
