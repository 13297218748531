import { RouteObject } from "react-router-dom";
import ForgotPasswordViewWrapper from "./forgot/wrapper";
import RecoverPasswordViewWrapper from "./recover/wrapper";

export const forgotPasswordRoutes: RouteObject[] = [
  { path: "forgot-password", element: <ForgotPasswordViewWrapper /> },
  {
    path: "recuperacion-de-contrasena",
    element: <RecoverPasswordViewWrapper />,
  },
];
