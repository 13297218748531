import styled from "styled-components";
import devices from "theme/devices";

import { StyledCol } from "./col.styles";

export const StyledRow = styled.div`
  @media ${devices.desktop} {
    flex: 1;
    flex-direction: row;
    display: flex;

    ${StyledCol} {
      width: 25%;
    }
  }
`;
