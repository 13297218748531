import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import devices from "theme/devices";

interface Props extends ThemeProps {
  $buttons: boolean;
}

export const StyledContainer = styled.div<Props>`
  margin-left: auto;
  margin-right: auto;
  z-index: 50;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;

  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.1px;

  @media ${devices.mobile} {
    padding-top: 2rem;
  }

  @media ${devices.desktop} {
    max-width: 1400px;
  }

  ${(props) =>
    props.$buttons &&
    css`
      z-index: 60;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      @media ${devices.tablet} {
        margin-bottom: 2rem;
      }

      @media ${devices.desktop} {
        position: relative;
        bottom: auto;
        padding-top: 3rem;
        align-items: flex-start;
      }
    `}
`;
