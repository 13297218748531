import { clear, read, write } from "./storage";
import { TOKEN, REFRESH_TOKEN } from "models/user";
import { getTokenExpire, refreshTokens } from "services/user";
import { getVersion } from "./version";
import { getApiUrl } from "./env";
import { gtmException, gtmRequest, gtmRequestDuration } from "./gtm";
import { DEVICE_ID_KEY } from "models/config";

let REFRESHING = false;

interface RequestOptions {
  // always mandatory
  url: string;

  // optionals
  method?: "GET" | "POST";
  headers?: Record<string, string>;

  // query params
  params?: any;

  // json body
  data?: any;

  // authenticated
  authenticated?: boolean;
}

interface ResponseModel<T> {
  status: number;
  data: T;
}

// construct headers
const clientVersion = `Kosmoweb/v${getVersion()}`;

// wrapper for ajax requests
// NOTE: it is better if the components handle the actual error handling AND possible spinners
export const request = async <T>(options: RequestOptions) => {
  const { method = "GET", authenticated = true } = options;
  console.info(`Request: ${options.url}`);

  let token: string | undefined;
  if (authenticated) {
    try {
      if (REFRESHING) {
        // if we are already refreshing tokens, please wait for a while
        console.info("Token refresh in progress, wait for a while...");
        await delay();
      }

      // fetch token, but only if the endpoint is authenticated
      // if the token is not valid, this will refresh automatically
      token = await getToken();
    } catch (err) {
      // clear things
      clear(TOKEN);
      clear(REFRESH_TOKEN);

      // redirect
      console.warn("fix me");
      //Router.push(translate("/login"));
      throw new Error(`Renewing token failed, redirected user to login page.`);
    }
  }

  // record start time of the request
  const timestamp = Date.now();

  // add jwt if available
  const headers = buildHeaders(options, token);

  // process url
  let url = `${getApiUrl()}${options.url}`;
  if (options.params) {
    url = `${url}?${new URLSearchParams(options.params)}`;
  }

  // options
  const init: RequestInit = {
    method,
    mode: "cors",
    credentials: "include",
    headers,
  };

  // add body
  if (options.data && method === "POST") {
    init.body = JSON.stringify(options.data);
  }

  // make request
  const response = await fetch(url, init);

  // send event to track the amount of the api request
  try {
    // send event to track api request duration
    gtmRequest(response.status, options.url);

    const duration = Date.now() - timestamp;
    gtmRequestDuration(duration, response.status, options.url);
  } catch (err) {
    // nada
  }

  // throw exception and report to GTM if the request fails
  if (response.ok === false) {
    const json = await response.json();
    const { code, message } = json;

    // always report failed requests
    reportError(response.status, options.url, message);

    // in case of non-401/login/refresh, throw error higher
    const throwError =
      response.status !== 401 ||
      url.includes("/login") ||
      url.includes("/refresh");
    if (throwError) {
      const text = `${response.status} error on ${options.url}: ${message}`;

      // nasty hack to overcome TS warning
      const ErrObject = Error as any;
      const err = new ErrObject(text, { cause: code });
      throw err;
    }
  }

  // return response
  try {
    const data = await response.json();
    return {
      status: response.status,
      data,
    } as ResponseModel<T>;
  } catch (err) {
    // can't parse response as JSON, in this case, just ignore
    return {
      status: response.status,
    } as ResponseModel<T>;
  }
};

const reportError = (
  status_code: number,
  endpoint: string,
  message: string
) => {
  gtmException(status_code, endpoint, message);
};

// get token, refresh if needed
const getToken = async () => {
  let token = read<string>(TOKEN);
  let refreshToken = read<string>(REFRESH_TOKEN);

  // first check token existence
  if (token && refreshToken) {
    if (getTokenExpire(token) < 0) {
      // mark file scope boolean to true
      REFRESHING = true;

      // refresh tokens
      const response = await refreshTokens(refreshToken);
      token = response.token;
      refreshToken = response.refreshToken;

      // persist
      write(TOKEN, token);
      write(REFRESH_TOKEN, refreshToken);

      // mark file scope boolean to false
      REFRESHING = false;
    }
  }

  return token;
};

// just an utility to build headers
const buildHeaders = (options: RequestOptions, token?: string) => {
  const { headers = {}, authenticated = true } = options;

  // add core fields
  headers["Content-Type"] = "application/json";
  headers["X-Kosmo-ClientVersion"] = clientVersion; // version from package.json

  // add bearer token
  if (authenticated && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  // add client uuid
  const deviceId = read<string>(DEVICE_ID_KEY);
  if (deviceId) {
    headers["X-Kosmo-DeviceId"] = deviceId;
  }

  // all done
  return headers;
};

const delay = async (): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, 2500));
};
