import { request } from "lib/request";
import { EntitlementModel } from "models/entitlement";

interface EntitlementResponse {
  entitlements: EntitlementModel[];
}

export const fetchEntitlement = async () => {
  const response = await request<EntitlementResponse>({
    method: "GET",
    url: "/v1/entitlement",
  });
  return response.data.entitlements[0];
};
