import { QuestionnaireContext } from "providers/questionnaire";
import { useContext } from "react";

// this returns hook to fetch current questionnaire provider
export const useQuestionnaire = () => {
  const context = useContext(QuestionnaireContext);
  if (!context) {
    throw new Error("No QuestionnaireProvider found!");
  }
  return context.questionnaire;
};

export const useUpdateQuestionnaire = () => {
  const context = useContext(QuestionnaireContext);
  if (!context) {
    throw new Error("No QuestionnaireProvider found!");
  }
  return context.updateQuestionnaire;
};
