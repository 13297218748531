import Block from "components/layout/block";
import styled from "styled-components";
import devices from "theme/devices";

export const StyledSpecialBlock = styled(Block)`
  display: none;

  @media ${devices.tablet} {
    display: inherit;
  }
`;
