import { isWindow } from "lib/env";
import { ThemeType } from "models/theme";
import { css } from "styled-components";
import { alpha, ColorCode } from "./colors";
import devices from "./devices";

/* make sure the component stretches to left and right border in mobile */
export const stretchToFullWidth = () => {
  return css`
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 2rem + 2rem);

    @media ${devices.tablet} {
      margin-left: -3.5rem;
      margin-right: -3.5rem;
      width: calc(100% + 3.5rem + 3.5rem);
    }

    @media ${devices.desktop} {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  `;
};

/* pull component upwards a bit, to overlap with content are margin */
export const stretchToTop = (theme: ThemeType) => {
  return css`
    margin-top: -1rem;

    ${theme === "MasterEnglish" &&
    css`
      margin-top: -1.75rem;
    `}

    @media ${devices.mobile} {
      margin-top: -2rem;
    }
  `;
};

export const opaque = () => {
  return css`
    background-color: ${alpha(ColorCode.BLACK, 0.5)};
    border-radius: 27px;
  `;
};

export const get100vh = () => {
  if (isWindow()) {
    return `${window.innerHeight}px`;
  }
  return "100vh";
};
