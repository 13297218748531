import { ConfigContext } from "providers/config";
import { useContext } from "react";

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("No ConfigProvider found!");
  }
  return context.config;
};

export const useUpdateConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("No ConfigProvider found!");
  }
  return context.updateConfig;
};
