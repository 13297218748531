import { ThemeProps } from "models/theme";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";

interface Props extends ThemeProps {}

export const StyledWait = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      color: ${ColorCode.BLACK};
    `}

  @media ${devices.tablet} {
    font-size: 1.25em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
  }
`;

export const StyledDots = styled.img`
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 0.1875em;
  width: 16px;
`;

export const StyledLeftOverAndDots = styled.span`
  white-space: nowrap;
`;
