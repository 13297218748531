import { css } from "styled-components";
import devices from "theme/devices";
import { stretchToFullWidth } from "theme/mixins";

const ImageCSS = css`
  ${stretchToFullWidth()};
  display: block;

  object-fit: cover;
  object-position: center top;

  @media ${devices.tablet} {
    max-height: 450px;
  }

  @media ${devices.desktop} {
    height: 100%;
    max-height: 1200px;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    object-position: center top;
    width: calc(50% - 3rem); /* 3rem is half of the "gap" between blocks */
    top: 0;
  }
`;

export const ImageCSSLeft = css`
  ${ImageCSS};
  left: 0;
`;

export const ImageCSSRight = css`
  ${ImageCSS};
  right: 0;
`;
