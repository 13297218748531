import { ThemeProps } from "models/theme";
import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";
import devices from "theme/devices";
import { archivo, light, regular, semibold } from "theme/fonts";
import Description from "./description";

export type ListType = "normal" | "checkmark" | "number" | "none";

export const StyledList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 1.75rem;

  @media ${devices.tablet} {
    margin-top: 2rem;
  }
`;

interface ItemProps {
  $listType: ListType;
}

export const StyledListItem = styled.li<ItemProps>`
  color: ${ColorCode.GRAY800};
  margin-bottom: 1rem;
  position: relative;
  display: flex;

  @media ${devices.tablet} {
    font-size: 1.25em;
    line-height: 1.375em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
    line-height: 1.375em;
    margin-bottom: 1rem;
  }

  ${(props) =>
    props.$listType === "none" &&
    css`
      margin-bottom: 1.25rem;
    `}
  ${(props) =>
    props.$listType === "normal" &&
    css`
      margin-bottom: 0.75rem;
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledListItemContent = styled.div`
  position: relative;
  flex: 1;
`;

interface TitleProps extends ThemeProps {
  $view: ViewType;
}

export const StyledListItemTitle = styled.div<TitleProps>`
  ${semibold()};
  position: relative;
  color: ${(props) =>
    props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      ${archivo()};
    `}
`;

interface TextProps extends ThemeProps {
  $view: ViewType;
}

export const StyledListItemText = styled.div<TextProps>`
  ${regular()};
  position: relative;

  ${(props) => css`
    color: ${props.$view === "dark" ? ColorCode.HEX_8C91A4 : ColorCode.GRAY800};

    ${props.$theme === "MasterEnglish" &&
    css`
      ${light()};
      color: ${props.$view === "dark" ? ColorCode.HEX_8C91A4 : ColorCode.BLACK};
    `}

    ${props.$theme === "HablaIngles" &&
    css`
      ${archivo()};
      color: ${props.$view === "dark"
        ? ColorCode.HEX_8C91A4
        : ColorCode.HEX_7F7F7F};
    `}
  `}
`;

interface DotProps extends ThemeProps {
  $view: ViewType;
}

export const StyledListDot = styled.div<DotProps>`
  width: 6px;
  height: 6px;
  background-color: ${ColorCode.BLUE};
  border-radius: 50%;
  margin-top: 0.5rem;
  margin-right: 0.75rem;
  flex-shrink: 0;

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      width: 3px;
      height: 3px;
      background-color: ${ColorCode.BLACK};
      ${props.$view === "dark" &&
      css`
        background-color: ${ColorCode.BLUE};
      `}
      margin-top: 0.8rem;
      margin-right: 0.75rem;
    `}

  @media ${devices.tablet} {
    width: 8px;
    height: 8px;
    margin-top: 0.65rem;
  }

  @media ${devices.desktop} {
    width: 8px;
    height: 8px;
    margin-top: 0.65rem;
    margin-right: 1rem;
  }
`;

interface ListNumberProps extends ThemeProps {}

export const StyledListNumber = styled.div<ListNumberProps>`
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -0.2rem;
  display: flex;
  background-color: ${ColorCode.BLUE};
  color: ${ColorCode.WHITE};
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  flex-shrink: 0;

  @media ${devices.tablet} {
    margin-top: -0.3rem;
    width: 2.25rem;
    height: 2.25rem;
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      display: none;
    `}
`;

export const StyledListCheckmark = styled.img`
  width: 1.75rem;
  margin-top: -0.1rem;
  align-self: flex-start;
  margin-right: 1rem;
  flex-shrink: 0;

  @media ${devices.desktop} {
    width: 2.625rem;
  }
`;

export const StyledListHeading = styled(Description)`
  margin-bottom: 1.5rem;
`;
