import { getMediaUrl } from "lib/media";
import { FunctionComponent } from "react";
import { StyledLogo, StyledLogos } from "./logos.styles";

const Logos: FunctionComponent = () => {
  return (
    <StyledLogos>
      <StyledLogo
        src={getMediaUrl("home/BreakthroughAward", {
          extension: "webp",
        })}
        alt="Breakthrough Award"
        fetchPriority="low"
      />
      <StyledLogo
        src={getMediaUrl("home/Patent", { extension: "webp" })}
        alt="Patent"
        fetchPriority="low"
      />
    </StyledLogos>
  );
};

export default Logos;
