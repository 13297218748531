import { RouteObject } from "react-router-dom";

// view wrappers
import { legalRoutes } from "views/legal/routes";
import { loginRoutes } from "views/login/routes";
import NotFoundViewWrapper from "views/not-found/wrapper";
import { paymentRoutes } from "views/payment/routes";
import { questionnaireRoutes } from "views/questionnaire/routes";
import { subscriptionRoutes } from "views/subscription/routes";
import { appRoutes } from "views/app/routes";
import { forgotPasswordRoutes } from "views/forgot-password/routes";
import { homeRoutes } from "views/home/routes";
import { linkRoutes } from "views/link/routes";

// use wrapper files to wrap the actual view, also the providers should be added there
const core = [
  ...homeRoutes,
  ...legalRoutes,
  ...subscriptionRoutes,
  ...forgotPasswordRoutes,
  ...appRoutes,
  ...linkRoutes,
  ...loginRoutes,
  ...questionnaireRoutes,
  ...paymentRoutes,

  // to catch them all
  { path: "*", element: <NotFoundViewWrapper /> },
] as RouteObject[];

export default core;
