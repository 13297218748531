import QuestionnaireProvider from "providers/questionnaire";
import { useParams, useSearchParams } from "react-router-dom";
import { QuestionnaireVersion } from "models/questionnaire";
import StateProvider from "providers/state";
import ConfigProvider from "providers/config";
import { StaticViewIdEnum } from "models/question";
import QuestionnaireReactivateView from "./view";
import ProductsProvider from "providers/products";
import ThemeProvider from "providers/theme";

const QuestionnaireReactivateViewWrapper = () => {
  const { version: v } = useParams();
  const [searchParams] = useSearchParams();
  const version = v as QuestionnaireVersion;

  // see if we have a discount code in url
  const discount = searchParams.get("discount") || undefined;

  return (
    <ThemeProvider>
      <ConfigProvider>
        <StateProvider>
          <QuestionnaireProvider
            version={version}
            questionId={StaticViewIdEnum.REACTIVATE}
          >
            <ProductsProvider reactivate discount={discount}>
              <QuestionnaireReactivateView />
            </ProductsProvider>
          </QuestionnaireProvider>
        </StateProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default QuestionnaireReactivateViewWrapper;
