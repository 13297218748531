import { ThemeProps } from "models/theme";
import { ViewType } from "models/view";
import styled, { css } from "styled-components";
import { ColorCode } from "theme/colors";

interface ViewLayoutWrapperProps {
  $cover: boolean;
}

export const StyledViewWrapper = styled.div<ViewLayoutWrapperProps>`
  position: absolute;
  width: 100%;
  height: auto;

  ${(props) => (props.$cover ? `height: 100%;` : "")}
`;

interface ViewLayoytProps extends ThemeProps {
  $view: ViewType;
}

export const StyledView = styled.div<ViewLayoytProps>`
  position: relative;
  z-index: 10;
  min-height: 100%;

  display: flex;
  flex-direction: column;

  ${(props) => css`
    color: ${props.$view === "dark" ? ColorCode.WHITE : ColorCode.GRAY800};
    background-color: ${props.$view === "dark"
      ? ColorCode.LAYOUT_DARK
      : "transparent"};

    h1,
    h2 {
      color: ${props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
    }

    b,
    strong {
      color: ${props.$view === "dark" ? ColorCode.WHITE : ColorCode.BLACK};
    }

    ${props.$theme === "HablaIngles" &&
    css`
      h1,
      h2 {
        color: ${props.$view === "dark" ? ColorCode.WHITE : "#273C7C"};
      }
    `}
  `};
`;
