import styled, { css } from "styled-components";

export type TransitionState = "fadeOut" | "fadeIn";

export const FADE_OUT_DELAY = 200; // length of fadeOut effect
export const PAUSE = 200; // time between fadeOut and fadeIn
export const FADE_IN_DELAY = 200; // length of fade effect

interface TransitionProps {
  state?: TransitionState;
}

export const StyledTransition = styled.div<TransitionProps>`
  transition: opacity ${FADE_IN_DELAY}ms ease-out;
  ${(props) =>
    props.state === "fadeOut" &&
    css`
      transition-duration: ${FADE_OUT_DELAY}ms;
      opacity: 0;
    `}
  ${(props) =>
    props.state === "fadeIn" &&
    css`
      transition-duration: ${FADE_IN_DELAY}ms;
      opacity: 1;
    `}
`;
