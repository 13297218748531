export const light = () => weight(300);

export const regular = () => weight(400);

export const semibold = () => weight(600);

export const weight = (weight: number) => `font-weight: ${weight};`;

export const opensans = () => `font-family: 'Open Sans', sans-serif;`;

export const archivo = () => `font-family: 'Archivo', sans-serif;`;
