import Badge from "components/badge";
import { ButtonBase } from "components/button.styles";
import ActionContainer from "components/layout/actions";
import styled from "styled-components";
import devices from "theme/devices";

export const StyledActionContainer = styled(ActionContainer)`
  padding: 0;
  margin-top: 2rem;

  ${ButtonBase} {
    margin-top: 0;
  }

  @media ${devices.tablet} {
    max-width: 720px;
    align-items: flex-start;
  }

  @media ${devices.desktop} {
    max-width: 720px;
    margin-top: 4rem;
    align-items: flex-start;
  }
`;

export const StyledBadge = styled(Badge)`
  @media ${devices.tablet} {
    max-width: 720px;
    margin: 0 auto;
  }

  @media ${devices.desktop} {
    max-width: 1920px;
    margin: 0 auto;
  }

  img {
    top: -0.25rem;
    right: 1rem;
    width: 81px;
    left: auto;

    @media ${devices.tablet} {
      top: 1em;
    }

    @media ${devices.desktop} {
      display: inline-block;
      right: auto;
      right: 2rem;
      top: 0.5rem;
    }
  }
`;
