import cleanDeep from "clean-deep";

function cleanJSON<T>(model: any): T {
  return cleanDeep(model, {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
  }) as T;
}

export { cleanJSON };
