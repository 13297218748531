import { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

interface HelmetProps {
  children: any;
}

const HelmetWrapper: FunctionComponent<HelmetProps> = ({ children }) => {
  // There is some issue in Types for Helmet, so casting it to "any"
  const HelmetCastedAsAny: any = Helmet;
  return <HelmetCastedAsAny>{children}</HelmetCastedAsAny>;
};

export default HelmetWrapper;
